import _URDNA from "./URDNA2015";
import _URGNA from "./URGNA2012";
import _URDNA2015Sync from "./URDNA2015Sync";
import _URGNA2012Sync from "./URGNA2012Sync";
import * as _rdfCanonizeNative2 from "rdf-canonize-native";

var _rdfCanonizeNative = "default" in _rdfCanonizeNative2 ? _rdfCanonizeNative2.default : _rdfCanonizeNative2;

import _NQuads from "./NQuads";
import _IdentifierIssuer from "./IdentifierIssuer";
var exports = {};
const URDNA2015 = _URDNA;
const URGNA2012 = _URGNA;
const URDNA2015Sync = _URDNA2015Sync;
const URGNA2012Sync = _URGNA2012Sync; // optional native support

let rdfCanonizeNative;

try {
  rdfCanonizeNative = _rdfCanonizeNative;
} catch (e) {}

const api = {};
exports = api; // expose helpers

api.NQuads = _NQuads;
api.IdentifierIssuer = _IdentifierIssuer;
/**
 * Get or set native API.
 *
 * @param api the native API.
 *
 * @return the currently set native API.
 */

api._rdfCanonizeNative = function (api) {
  if (api) {
    rdfCanonizeNative = api;
  }

  return rdfCanonizeNative;
};
/**
 * Asynchronously canonizes an RDF dataset.
 *
 * @param dataset the dataset to canonize.
 * @param options the options to use:
 *          algorithm the canonicalization algorithm to use, `URDNA2015` or
 *            `URGNA2012`.
 *          [useNative] use native implementation (default: false).
 *          [maxDeepIterations=Infinity] the maximum number of times to run
 *            deep comparison algorithms (such as the N-Degree Hash Quads
 *            algorithm used in URDNA2015) before bailing out and throwing an
 *            error; this is a useful setting for preventing wasted CPU cycles
 *            or DoS when canonizing meaningless or potentially malicious
 *            datasets, a recommended value is `1`.
 *
 * @return a Promise that resolves to the canonicalized RDF Dataset.
 */


api.canonize = async function (dataset, options) {
  // back-compat with legacy dataset
  if (!Array.isArray(dataset)) {
    dataset = api.NQuads.legacyDatasetToQuads(dataset);
  }

  if (options.useNative) {
    if (!rdfCanonizeNative) {
      throw new Error("rdf-canonize-native not available");
    } // TODO: convert native algorithm to Promise-based async


    return new Promise((resolve, reject) => rdfCanonizeNative.canonize(dataset, options, (err, canonical) => err ? reject(err) : resolve(canonical)));
  }

  if (options.algorithm === "URDNA2015") {
    return new URDNA2015(options).main(dataset);
  }

  if (options.algorithm === "URGNA2012") {
    return new URGNA2012(options).main(dataset);
  }

  if (!("algorithm" in options)) {
    throw new Error("No RDF Dataset Canonicalization algorithm specified.");
  }

  throw new Error("Invalid RDF Dataset Canonicalization algorithm: " + options.algorithm);
};
/**
 * This method is no longer available in the public API, it is for testing
 * only. It synchronously canonizes an RDF dataset and does not work in the
 * browser.
 *
 * @param dataset the dataset to canonize.
 * @param options the options to use:
 *          algorithm the canonicalization algorithm to use, `URDNA2015` or
 *            `URGNA2012`.
 *          [useNative] use native implementation (default: false).
 *          [maxDeepIterations=Infinity] the maximum number of times to run
 *            deep comparison algorithms (such as the N-Degree Hash Quads
 *            algorithm used in URDNA2015) before bailing out and throwing an
 *            error; this is a useful setting for preventing wasted CPU cycles
 *            or DoS when canonizing meaningless or potentially malicious
 *            datasets, a recommended value is `1`.
 *
 * @return the RDF dataset in canonical form.
 */


api._canonizeSync = function (dataset, options) {
  // back-compat with legacy dataset
  if (!Array.isArray(dataset)) {
    dataset = api.NQuads.legacyDatasetToQuads(dataset);
  }

  if (options.useNative) {
    if (rdfCanonizeNative) {
      return rdfCanonizeNative.canonizeSync(dataset, options);
    }

    throw new Error("rdf-canonize-native not available");
  }

  if (options.algorithm === "URDNA2015") {
    return new URDNA2015Sync(options).main(dataset);
  }

  if (options.algorithm === "URGNA2012") {
    return new URGNA2012Sync(options).main(dataset);
  }

  if (!("algorithm" in options)) {
    throw new Error("No RDF Dataset Canonicalization algorithm specified.");
  }

  throw new Error("Invalid RDF Dataset Canonicalization algorithm: " + options.algorithm);
};

export default exports;